<template>
  <div>
    <!-- 优惠券列表标题 -->
    <li>
      <h3
        class="bsc-coupon-list__coupon-wrap_coupon-title bsc-coupon-list__pd-32rem"
      >
        {{ title }}
      </h3>
    </li>

    <!-- 券凑单可叠加提示语 -->
    <li
      v-if="stackableInfoRef"
      v-expose="{
        id: 'expose_couponaddon_banner.comp_coupon-list'
      }"
      class="bsc-coupon-list__coupon-wrap_addOn-tips bsc-coupon-list__pd-32rem"
    >
      <span>
        <img
          v-if="stackableInfoRef?.icon"
          :src="stackableInfoRef?.icon"
          alt=""
          width="16px"
          height="16px"
        />
        <span
          class="bsc-coupon-list__add-on-text"
          v-html="stackableInfoRef?.tip"
        ></span>
      </span>
      <!-- 跳转unavaliable的view按钮 -->
      <SButton
        v-if="stackableInfoRef?.tip"
        :type="['primary', 'H48PX']"
        class="bsc-coupon-list__coupon-wrap_recommend-btn"
        @click="clickStackIcon"
      >
        {{ language?.SHEIN_KEY_PWA_37198 || 'Add' }}
      </SButton>
    </li>

    <!-- 最优券提示及使用 -->
    <li
      v-if="isBestCouponVisible"
      class="bsc-coupon-list__coupon-wrap_recommend-tips bsc-coupon-list__pd-32rem"
    >
      <span>
        <!-- 最优券提示icon -->
        <i
          v-if="isBestSelected"
          class="suiiconfont sui_icon_selected_16px"
        ></i>
        <!-- 最优券提示文案 -->
        <span v-html="bestCouponInfoRef?.bestCombinationCouponTip"></span>
      </span>
      <!-- 使用最优券APPLY按钮 -->
      <SButton
        v-if="!isBestSelected"
        v-expose="bestBtnExpose()"
        v-tap="bestBtnClickAlc()"
        :type="['primary', 'H48PX']"
        class="bsc-coupon-list__coupon-wrap_recommend-btn"
        @click="emit('use-best-coupon')"
      >
        {{ language?.SHEIN_KEY_PWA_25059 }}
      </SButton>
    </li>
    <!-- 优惠券列表 -->
    <li
      v-for="(item, index) in listData"
      :id="`coupon-item-${item?.coupon}`"
      :key="`${keyStr}-${index}-${item.coupon}`"
      v-enterkey
      class="bsc-coupon-list__coupon-wrap_item-new"
      tabindex="0"
      :aria-checked="item.businessExtension?.order?.isCheck != 0"
    >
      <!-- 新优惠券组件插槽 -->
      <slot
        name="new-coupon-item"
        :new-coupon-item-config="item"
        v-bind="{
          data: item,
          checkoutCouponIndex: `${keyStr}-${index}`,
          couponConfig: getCouponConfig (item),
          couponType: couponType,
          index: index,
        }"
      ></slot>
    </li>
  </div>
</template>
<script setup lang="ts">
  import type { PROP_CouponList, RES_CouponList, C_CouponList } from '../../../types'
  import { COUPON_TYPE } from '../../../common/utils/constants'
  import { SButton } from '@shein-aidc/sui-button/mobile'
  import { computed, ref, inject } from 'vue'
  import { useCoupon } from '../hooks/useCoupon'
  import useState from '../hooks/useState.ts'

  //注入埋点实例
  const {vTap, vExpose, triggerNotice} = inject('analysisInstance') as C_CouponList.AnalysisInstance
  const { vEnterkey } = useCoupon()

  const { addItemInfoListRef } = useState

  const emit = defineEmits(['use-best-coupon', 'switch-to-unavailable'])

  type propsType = {
    title?: string | null | undefined,
    couponType?: string,
    listData?: Array<RES_CouponList.CommonCoupon> | null | undefined,
    bestCouponInfoRef?: PROP_CouponList.BestCoupon,
    stackableInfoRef?: PROP_CouponList.StackableInfo,
    language?: any,
    keyStr?: string,
  }

  const props = withDefaults(defineProps<propsType>(), {
    title: '',
    stackableInfoRef: null,
    couponType: '',
  })

  // 基本优惠券配置
  const baseCouponConfigs = ref({
    activityFrom: 'order_Component',
    waterMark: 0,
    assistInformationType: 1,
  })

  // 是否选中最优券
  const isBestSelected = computed(() => props.bestCouponInfoRef?.is_best_selected == 1)

  // 最优券提示及apply是否展示
  const isBestCouponVisible = computed(() => props.couponType === COUPON_TYPE.BEST_COUPON)

  // 计算埋点code
  const recommemdAnalysis = () => {
    if(props.couponType != COUPON_TYPE.BEST_COUPON) {
      return ''
    }
    return props.listData?.map(item => (item.couponCode || item.coupon || ''))?.join(',')?.toLowerCase() || ''
  }

  const clickStackIcon = () => {
    triggerNotice && triggerNotice({
      id: 'click_couponaddon_banner.comp_coupon-list',
    })
    emit('switch-to-unavailable')
  }

  // 曝光事件
  const bestBtnExpose = () => ({
    id: 'expose_apply_recommend_coupon.comp_coupon-list',
    data: { code: recommemdAnalysis() },
  })

  // 点击最优券apply按钮 埋点事件
  const bestBtnClickAlc = () => ({
    id: 'click_apply_recommend_coupon.comp_coupon-list',
    data: { code: recommemdAnalysis() },
  })

  // 获取最优券配置
  const getCouponConfig = (item) => {
    // 凑单券凑单进度条曝光 0在可用tab  1在不可用tab
    let itemIsInUsableTab = props?.keyStr == 'added-coupon-item' ? 0 : 1
    let configObj = {
      ...baseCouponConfigs.value,
      couponStatus: 1,
      addModule: item?.addModule || null,
      progressExposeData: {
        id: 'expose_couponaddon_line:simple',
        data: {
          scene: itemIsInUsableTab,
        },
      },
    }
    return configObj
  }
</script>
