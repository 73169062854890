<template>
  <div class="promotion-content">
    <div
      v-for="(item, index) in productList"
      :key="index"
      class="promotion-content__item"
    >
      <div
        v-if="item.display_module_title"
        class="promotion-content__item-title"
      >
        {{ item.display_module_title }}
      </div>
      <div class="promotion-content__products">
        <img
          v-for="(img, imgIndex) in item.cart_id_imgs"
          :key="imgIndex"
          class="promotion-content__products-img"
          :src="transformImg({ img })"
        />
      </div>
      <div class="promotion-content__desc">
        <span>
          {{ item.display_title }}
          <SPopover
            :append-to-body="false"
            :content="item.tip"
            :placemen="index === productList.length - 1 ? 'top-start' : 'bottom-start'"
            show-close-icon
            :prop-style="{
              maxWidth: '240px',
              width: 'max-content'
            }"
          >
            <template #reference>
              <Icon
                v-if="item.tip"
                name="sui_icon_doubt_16px_2"
                size="14px"
                style="line-height: 1.2;"
              />
            </template>
          </SPopover>
        </span>
        <template v-if="item.display_price_text">
          <span>|</span>
          <span class="promotion-content__desc-saved">
            {{ item.display_price_text }}
          </span>
        </template>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { transformImg } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue3'
import { SPopover } from '@shein-aidc/sui-popover/mobile'
import type { C_CheckoutPrice } from '../../../../types'

defineProps<{
  productList: C_CheckoutPrice.InteractionListItem[]
}>()

</script>
<style lang="less" scoped>
.promotion-content {
  background-color: #F6F6F6;

  &__item {
    padding: 16/75rem 24/75rem;

    &:not(:last-child) {
      padding-bottom: 0;

      &::after {
        content: '';
        display: block;
        height: 1/75rem;
        width: 664/75rem;
        background-color: #E5E5E5;
        margin-top: 16/75rem;
      }
    }
  }

  &__item-title {
    color: #000;
    font-size: 14px;
    margin-bottom: 16/75rem;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  &__products {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    margin-bottom: 16/75rem;

    /*隐藏滚动条，当IE下溢出，仍然可以滚动*/
    -ms-overflow-style: none;
    /*火狐下隐藏滚动条*/
    scrollbar-width: none;
    /*Chrome下隐藏滚动条，溢出可以透明滚动*/
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__products-img {
    width: 128/75rem;
    height: 128/75rem;
    object-fit: cover;
    margin-left: 12/75rem;

    &:first-child {
      margin-left: 0;
    }
  }

  &__desc {
    color: #767676;
    font-family: "SF Pro";
    font-size: 12px;
  }

  &__desc-saved {
    color: #FA6338;
    margin-left: 8/75rem;
  }
}
</style>
