<template>
  <!-- 优惠券列表头部组件 -->
  <!-- 标题导航组件 -->
  <STitleNav
    ref="myCouponTitle"
    class="bsc-coupon-list__header"
    :title="title || 'My Coupons'"
  >
    <!-- <template
      v-if="!isHideBackIcon"
      #preblock
    >
      <STitleNavItem
        icon-class="suiiconfont sui_icon_nav_back_24px"
        role="button"
        @keyup.enter.stop.prevent="emit('go-back')"
        @click="emit('go-back')"
      />
    </template> -->

    <template
      v-if="!isHideBackIcon"
      #prepend
    >
      <div @keyup.enter.stop.prevent="emit('go-back')">
        <STitleNavItem
          @click="emit('go-back')"
        >
          <template #default>
            <Icon name="sui_icon_nav_back_24px" size="24px" />
          </template>
        </STitleNavItem>
      </div>
    </template>
  </STitleNav>
</template>
<script setup lang="ts">
  import { template as _commonTemplate } from '@shein/common-function'
  import { STitleNav } from '@shein-aidc/sui-title-nav/mobile'
  import { STitleNavItem } from '@shein-aidc/sui-title-nav-item/mobile'
  import { Icon } from '@shein-aidc/icon-vue3'

  import { defineEmits, withDefaults } from 'vue'

  const emit = defineEmits([
    'go-back',
  ])

  type propsType = {
    isHideBackIcon?: boolean,
    title?: string,
  }
  withDefaults(defineProps<propsType>(), {
    title: () => 'My Coupons',
    isHideBackIcon: () => false,
  })
</script>
<style lang="less">
.bsc-coupon-list__drawer .bsc-coupon-list__header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4;
}
.S-title-nav__content {
  max-width: 80% !important;
}
</style>"
