<template>
  <div v-if="addressList && addressList.length">
    <div
      v-for="(item, index) in addressList"
      :key="index"
      class="available-address-item"
    >
      <div
        class="available-address-item__top"
        @click.self.stop="handleSelectAddress(item, index)"
      >
        <div
          class="address-detail"
          @click.stop="handleSelectAddress(item, index)"
        >
          <!-- 名称、电话 -->
          <div class="address-detail__top">
            <div class="address-detail__top-name"> {{ getAddressName(item) }}</div>
            <div class="address-detail__top-phone"> {{ item.tel }}</div>
          </div>
          <!-- 地址信息 -->
          <p class="address-detail__bottom-address">{{ getAddressDetailFirstLine(item) }}</p>
          <p class="address-detail__bottom-address">{{ getAddressDetailSecondLine(item) }}</p>
          <!-- 敏感信息提示 -->
          <div
            v-if="getSensitiveNote(item)"
            class="address-detail__tips"
          >
            {{ getSensitiveNote(item) }}
          </div>
        </div>
        <Radio
          v-model="item.selected"
          margin-r="0"
          :disabled="disableStyle"
          :label="1"
          @change="handleSelectAddress(item, index)"
        />
      </div>
      <div class="available-address-item__bottom">
        <div class="address-label-wrap">
          <Label
            v-if="item.isDefault === '1'"
            class="address-label-wrap__label"
          >
            {{ language.Default }}
          </Label>
          <span
            v-if="item.countryId == countryOrAreaIdMap.IN && item.type !== '0'"
            :class="['address-label-wrap__type-text', {'address-label-wrap__type-text-work': item.type === '2'}]"
            v-html="item.type === '1' ? language.Home : language.Work"
          ></span>
        </div>
        <div
          class="address-edit-wrap"
          @click="handleEditAddress(item)"
        >
          <Icon
            class="address-edit-wrap__icon"
            name="sui_icon_edit_20px"
            size="20px"
          />
          <span class="address-edit-wrap__text">{{ language.Edit }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script name="AvailableAddress" setup lang="ts">
import { computed, defineEmits } from 'vue'
import { SRadio as Radio } from '@shein-aidc/sui-radio/mobile'
import { SLabel as Label } from '@shein-aidc/sui-label/mobile'
import { Icon } from '@shein-aidc/icon-vue3'
import { countryOrAreaIdMap } from '../../common/config/common'
import { getAddressName } from '../../common/config/tools'
import type { C_Addresslist } from './../../types/index'

const props = defineProps<{
  addressList: C_Addresslist.IDefaultAddress[]
  language: C_Addresslist.LanguageType
}>()

const emits = defineEmits<{
  (event: 'selectedAddress', params: { address: C_Addresslist.IDefaultAddress, index: number }): void
  (event: 'editAddress', item: C_Addresslist.IDefaultAddress): void
}>()


const disableStyle = computed((): boolean => props.addressList.length === 1 && Number(props.addressList[0].selected) === 1)

/**
 * @description: 获取地址详情的第一行数据
 * @param {*} addressInfo 当前的地址信息
 * @return {*}
 */
const getAddressDetailFirstLine= (addressInfo: C_Addresslist.IDefaultAddress = {}):string => {
  const { postcode, address1, address2, type, countryId } = addressInfo
  const isJp = countryId === countryOrAreaIdMap.JP
  const isTwArea = countryId === countryOrAreaIdMap.TW && type == '3'

  if (isJp) {
    return `〒 ${postcode}`
  }
  if (isTwArea) {
    return `${address1} ${address2} ${props.language.Collection}`
  }
  return `${address1} ${address2}`
}

/**
 * @description: 获取地址详情的第二行数据
 * @param {*} addressInfo 当前的地址信息
 * @return {*}
 */
 const getAddressDetailSecondLine= (addressInfo: C_Addresslist.IDefaultAddress = {}):string => {
  const { postcode, address1, address2, countryId, countryName, state, city, district } = addressInfo
  const isJp = countryId === countryOrAreaIdMap.JP

  if (isJp) {
    return `${countryName} ${state} ${city} ${district != 0 ? district : ''} ${address1} ${address2}`
  }
  return `${district != 0 ? district : ''} ${city} ${state} ${countryName} ${postcode}`
}

/**
 * @description: 获取敏感词
 * @param {*} addressInfo
 * @return {*}
 */
const getSensitiveNote = (addressInfo: C_Addresslist.IDefaultAddress = {}) => {
  return addressInfo.sensitiveRule?.sensitiveTip || ''
}

/**
 * @description: 选中地址，当前仅有一个可选地址时，添加禁用样式且只能通过点击back按钮回去
 * @param {*} item 当前地址信息
 * @param {*} index 当前索引
 * @return {*}
 */
const handleSelectAddress = (item: C_Addresslist.IDefaultAddress, index:number): void => {
  if (disableStyle.value) return
  emits('selectedAddress', { address: item, index })
}

/**
 * @description: 编辑地址
 * @param {*} item 当前地址信息
 * @return {*}
 */
const handleEditAddress = (item: C_Addresslist.IDefaultAddress): void => {
  emits('editAddress', item)
}
</script>

<style lang="less" scoped>
.available-address-item {
  margin-bottom: 20/75rem;
  background: #fff;
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30/75rem;
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14/75rem 0;
    border-top: 2/75rem solid #e5e5e5;
    margin: 0 24/75rem;
  }
}
.address-detail {
  padding-right: 24/75rem;
  &__top {
    display: flex;
    margin-bottom: 8/75rem;
    font-size: 14px;
    line-height: 19px;
  }
  &__top-name {
    margin-right: 16/75rem;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__top-phone {
    font-size: 12px;
    color: #666;
    white-space: nowrap;
  }
  &__bottom-address {
    margin-bottom: 8/75rem;
    font-size: 14px;
    line-height: 17px;
    color: #666;
    white-space: normal;
    word-break: break-all;
  }
  &__tips {
    padding-top: 8/75rem;
    color: #bf4123;
    font-size: 12px;
    white-space: normal;
    word-break: break-all;
  }
}
.address-label-wrap {
  &__label {
    margin-right: 8/75rem;
  }
  &__type-text {
    display: inline-block;
    padding: 0 8/75rem;
    font-size: 11px;
    color: #902cb7;
    border: 4/75rem solid #902cb7;
  }
  &__type-text-work {
    color: #4b9eff;
    border-color: #4b9eff;
  }
}
.address-edit-wrap {
  display: flex;
  align-items: center;
  color: #222;
  padding: 10/75rem 0;
  &__text {
    font-size: 12px;
    margin-left: 8/75rem;
  }
}
</style>
