<template>
  <div
    v-if="isShowAddOrderEntry"
    v-expose="{
      id: 'expose_price_add_shipping.comp_checkout-price',
      data: addOrderInfo.analyzeData
    }"
    :class="['checkout-add-item', !isNewStyle && 'checkout-add-item_side-gap']"
    @click.stop="handleAddFreeClick"
  >
    <!-- 左边 -->
    <div class="checkout-add-item__left-wrap">
      <Icon
        name="sui_icon_freeshipping_flat_24px"
        size="14px"
        color="#198055"
      />
      <span
        class="checkout-add-item__left-wrap-desc"
        v-html="hints"
      >
      </span>
    </div>
    <!-- 右边 -->
    <div class="checkout-add-item__right-wrap">
      {{ addOrderInfo?.btnText || 'Add' }}
    </div>
  </div>
</template>

<script name="CheckoutPriceDetailAddItem" setup lang="ts">
import { computed } from 'vue'
import { template } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue3'

const props = defineProps({
  addOrderInfo: {
    type: Object,
    default: () => ({}),
  },
  isNewStyle: {
    type: Boolean,
    default: true,
  },
})

const emits = defineEmits(['add-order'])

const isShowAddOrderEntry = computed(() => {
  return props.addOrderInfo?.isShow
})

const hints = computed(() => {
  const { tip, replaceText = [] } = props.addOrderInfo?.showTip || {}
  const args = replaceText?.map(item => {
    return `<span style="color:${item.color}; font-weight: ${item.bold ? 'bold' : 'normal'}">${item.text}</span>`
  }) || []

  return template(...args, tip)
})

const handleAddFreeClick = () => {
  emits('add-order')
}
</script>

<style lang="less" scoped>
.checkout-add-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F5FCFB;
  height: 80/75rem;
  padding: 16/75rem 24/75rem;
  position: relative;
  &_side-gap {
    margin-left: -24/75rem;
    margin-right: -24/75rem;
  }
  &::before {
    position: absolute;
    width: 0;
    height: 0;
    content: '';
    border-left: 10/75rem solid transparent;
    border-right: 10/75rem solid transparent;
    border-bottom: 8/75rem solid black;
    border-color: #F5FCFB;
    top: 0;
    left: 116/75rem;
    transform: translateY(-100%);
  }
  &__left-wrap {
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1;
  }
  &__left-wrap-desc {
    margin-left: 8/75rem;
    color: var(---sui_color_safety, #198055);
    font-family: "SF Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 590;
  }
  &__right-wrap {
    min-width: 100/75rem;
    max-width: 140/75rem;
    padding: 8/75rem 20/75rem;
    border-radius: 200/75rem;
    border: 2/75rem solid @sui_color_brand;
    margin-left: 8/75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    color: var(---sui_color_brand, #000);
    font-size: 12px;
    font-style: normal;
    font-weight: 510;
    height: 52/75rem;
    line-height: 17px;
  }
}
</style>
