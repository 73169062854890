<template>
  <LazyMount>
    <SDrawer
      :visible="visible"
      class="price-details-drawer"
      max-size="70%"
      :show-close="false"
      append-to-body
      @close-from-mask="handleClose"
    >
      <template #title>
        <DrawerHeader
          :item="item"
          @show-tips="(data) => emits('show-tips', data)"
          @close="handleClose"
        />
      </template>
      <component
        :is="contentComponent.name"
        v-bind="contentComponent.props"
        @show-tips="(data) => emits('show-tips', data)"
      />
      <template #footer>
        <SButton
          :type="['H72PX', 'primary']"
          :width="'100%'"
          @click="handleClose"
        >
          {{ btnText }}
        </SButton>
      </template>
    </SDrawer>
  </LazyMount>
</template>
<script name="PriceDetailsDrawer" setup lang="ts">
import { computed } from 'vue'
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SLazyMount as LazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import { C_CheckoutPrice, E_CheckoutPrice } from '../../../../types'
import DrawerHeader from './drawer-header.vue'
import PromotionContent from './promotion-content.vue'
import CouponContent from './coupon-content.vue'
import TaxContent from './tax-content.vue'

const props = defineProps<{
  visible: boolean,
  item: C_CheckoutPrice.PriceItemInfo,
  btnText: string
}>()

const emits = defineEmits(['close', 'show-tips'])

const contentComponent = computed(() => {
  switch(props.item.interaction_info?.type) {
    case E_CheckoutPrice.PopupType.Promotion:
      return {
        name: PromotionContent,
        props: {
          productList: props.item.interaction_info?.item_list || [],
        },
      }
    case E_CheckoutPrice.PopupType.Coupon:
      return {
        name: CouponContent,
        props: {
          productList: props.item.interaction_info?.item_list || [],
        },
      }
    case E_CheckoutPrice.PopupType.Tax:
      return {
        name: TaxContent,
        props: {
          taxList: props.item.interaction_info?.tax_sub_price_details || [],
        },
      }
    default:
      return {} as any
  }
})

const handleClose = () => {
  emits('close')
}
</script>
<style lang="less" scoped>
.price-details-drawer {
  line-height: 1.2;

  /deep/ .sui-drawer__container {
    border-radius: 16/75rem 16/75rem 0 0;
    background-color: #fff;
  }

  /deep/ .sui-drawer__header {
    min-height: auto;
    line-height: 1.2;
  }

  /deep/ .sui-drawer__normal-footer {
    margin: 32/75rem 24/75rem 16/75rem;
    height: auto;
    line-height: inherit;
  }

  &__header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__title {
    max-width: 566/75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000;
    font-size: 16px;
    font-weight: 510;
    padding: 18/75rem 0;
  }

  &__subtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20/75rem 24/75rem 16/75rem;
    color: #FA6338;
    font-size: 14px;
    font-weight: 590;
  }

  &__subtitle-value {
    font-size: 20px;
    font-weight: 700;
  }

  &__close {
    position: absolute;
    top: 18/75rem;
    right: 16/75rem;
  }
}
</style>
