<template>
  <LazyMount>
    <SDialog
      class="price-tips-dialog"
      :visible="visible"
      append-to-body
      :show-close="true"
      :close-on-click-modal="false"
      @close="emits('close')"
    >
      <p v-html="content"></p>
      <template #footer>
        <SButton
          :type="['H72PX', 'primary']"
          :width="'100%'"
          @click="emits('close')"
        >
          {{ btnText }}
        </SButton>
      </template>
    </SDialog>
  </LazyMount>
</template>

<script name="PriceTipsDialog" setup lang="ts">
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SLazyMount as LazyMount } from '@shein-aidc/sui-lazy-mount/mobile'

defineProps<{
  visible: boolean,
  content: string,
  btnText: string
}>()

const emits = defineEmits(['close'])

</script>

<style lang="less" scoped>
.price-tips-dialog {
  text-align: center;
}
</style>
