// 附加组件样式 https://wiki.dotfashion.cn/pages/viewpage.action?pageId=1311682351
export const COUPON_HANDLE_TYPE = {
  onlyGetList: 'onlyGetList', // 仅获取券列表数据
  applyBestCoupon: 'applyBestCoupon', // 使用最佳优惠券
  newApplyCoupin: 'newApplyCoupin', //手动用券
  clickSelectCoupon: 'clickSelectCoupon', // 点击选择优惠券
}

// 券分类
export const COUPON_TYPE = {
  // 最优券
  BEST_COUPON: 'BEST_COUPON',
  ADD_COUPON: 'ADD_COUPON',
  COMMON_COUPON: 'COMMON_COUPON',
  COMMON_DISABLE: 'COMMON_DISABLE',
  ADD_ITEM: 'ADD_ITEM', // 凑单券
}

// 券列表组件使用的场景
export const COUPON_LIST_SCENE = {
  // 普通下单
  NOMAL_CHECKOUT: 'NOMAL_CHECKOUT',
  // 会员卡
  CASHIER_PRIME: 'CASHIER_PRIME',
  // 超省卡
  CASHIER_SAVE_CARD: 'CASHIER_SAVE_CARD',
}
