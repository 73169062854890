<template>
  <div v-if="addressList && addressList.length">
    <!-- 不可用的地址 提示 -->
    <div class="unavailable-address-tip">{{ unavailableTips }}</div>
    <!-- 地址数据 -->
    <div
      v-for="(item, index) in addressList"
      :key="index"
      class="unavailable-address-item"
    >
      <div class="address-content">
        <div class="address-content__left address-detail">
          <!-- 名称、电话 -->
          <div class="address-detail__top">
            <div class="address-detail__top-name"> {{ getAddressName(item) }}</div>
            <div class="address-detail__top-phone"> {{ item.tel }}</div>
          </div>
          <!-- 地址信息 -->
          <p class="address-detail__bottom-address">{{ getAddressDetailFirstLine(item) }}</p>
          <p class="address-detail__bottom-address">{{ getAddressDetailSecondLine(item) }}</p>
          <!-- 敏感信息提示 -->
          <div
            v-if="getSensitiveNote(item)"
            class="address-detail__tips"
          >
            {{ getSensitiveNote(item) }}
          </div>
        </div>
        <Icon
          class="address-content__right"
          name="sui_icon_more_right_16px_2"
          size="20px"
        />
      </div>
      <div class="address-operate-wrap">
        <Icon
          class="address-operate-wrap__icon"
          name="sui_icon_delete_20px"
          size="20px"
          @click="handleDeleteAddress(item)"
        />
      </div>
    </div>
    <!-- 删除提醒弹窗 -->
    <Dialog
      v-model:visible="isShowDeleteWarn"
      append-to-body
      class="delete-dialog-wrapper"
    >
      <div class="delete-dialog-wrapper__content">
        {{ language.ConfirmTitle }}
      </div>
      <template
        #footer
      >
        <ButtonGroup
          hor
          width="100%">
          <ButtonGroupItem
            class="delete-dialog-wrapper__btn"
            :type="['primary','H72PX']"
            @click="handleConfirmClick"
          >
            {{ language.ConfirmDelete }}
          </ButtonGroupItem>
          <ButtonGroupItem
            class="delete-dialog-wrapper__btn"
            :type="['H72PX']"
            @click="isShowDeleteWarn = false"
          >
            {{ language.ConfirmCancel }}
          </ButtonGroupItem>
        </ButtonGroup>
      </template>
    </Dialog>
  </div>
</template>

<script name="UnavailableAddress" setup lang="ts">
import { ref, reactive, defineEmits, defineProps } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { SDialog as Dialog } from '@shein-aidc/sui-dialog/mobile'
import { SButtonGroup as ButtonGroup } from '@shein-aidc/sui-button-group/mobile'
import { SButtonGroupItem as ButtonGroupItem } from '@shein-aidc/sui-button-group-item/mobile'
import { countryOrAreaIdMap } from '../../common/config/common'
import { getAddressName } from '../../common/config/tools'
import type { C_Addresslist } from './../../types/index'

defineProps<{
  addressList: C_Addresslist.IDefaultAddress[]
  language: C_Addresslist.LanguageType
  unavailableTips: string
}>()

const emits = defineEmits<{
  (event: 'deleteAddress', item: C_Addresslist.IDefaultAddress): void
}>()

// 是否展示删除提醒弹窗
const isShowDeleteWarn = ref<boolean>(false)
const deleteAddressInfo = reactive<C_Addresslist.IDefaultAddress>({})

/**
 * @description: 获取地址详情的第一行数据
 * @param {*} addressInfo 当前的地址信息
 * @return {*}
 */
 const getAddressDetailFirstLine= (addressInfo: C_Addresslist.IDefaultAddress = {}):string => {
  const { postcode, address1, address2, countryId } = addressInfo
  const isJp = countryId === countryOrAreaIdMap.JP

  if (isJp) {
    return `〒 ${postcode}`
  }
  return `${address1} ${address2}`
}

/**
 * @description: 获取地址详情的第二行数据
 * @param {*} addressInfo 当前的地址信息
 * @return {*}
 */
 const getAddressDetailSecondLine= (addressInfo: C_Addresslist.IDefaultAddress = {}):string => {
  const { postcode, address1, address2, countryId, countryName, state, city, district, street } = addressInfo
  const isJp = countryId === countryOrAreaIdMap.JP

  if (isJp) {
    return `${countryName} ${state} ${city} ${district != 0 ? district : ''} ${street} ${address1} ${address2}`
  }
  return `${street} ${district != 0 ? district : ''} ${city} ${state} ${countryName} ${postcode}`
}

/**
 * @description: 获取敏感词
 * @param {*} addressInfo
 * @return {*}
 */
const getSensitiveNote = (addressInfo: C_Addresslist.IDefaultAddress = {}) => {
  return addressInfo.sensitiveRule?.sensitiveTip || ''
}

/**
 * @description: 删除地址
 * @param {*} item 当前地址信息
 * @return {*}
 */
const handleDeleteAddress = (item: C_Addresslist.IDefaultAddress): void => {
  Object.assign(deleteAddressInfo, item)
  isShowDeleteWarn.value = true
}

/**
 * @description: 处理删除弹窗提醒的确定按钮操作
 */
const handleConfirmClick = (): void => {
  isShowDeleteWarn.value = false
  emits('deleteAddress', deleteAddressInfo)
}

</script>

<style lang="less" scoped>
.unavailable-address-tip {
  padding: 20/75rem 24/75rem;
  font-size: 14px;
  color: #666;
}
.unavailable-address-item {
  margin-bottom: 20/75rem;
  background: #fff;
}
.address-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30/75rem;
  opacity: 0.4;
  &__left {
    padding-right: 24/75rem;
  }
}
.address-detail {
  &__top {
    display: flex;
    margin-bottom: 8/75rem;
    font-size: 14px;
    line-height: 19px;
  }
  &__top-name {
    margin-right: 16/75rem;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__top-phone {
    font-size: 12px;
    color: #666;
    white-space: nowrap;
  }
  &__bottom {
    margin-bottom: 8/75rem;
    font-size: 14px;
    line-height: 17px;
    color: #666;
    white-space: normal;
    word-break: break-all;
  }
  &__tips {
    padding-top: 8/75rem;
    color: #bf4123;
    font-size: 12px;
    white-space: normal;
    word-break: break-all;
  }
}
.address-operate-wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 14/75rem 0;
  border-top: 2/75rem solid #e5e5e5;
  margin: 0 24/75rem;
}
.delete-dialog-wrapper {
  &__content {
    text-align: center;
  }
  &__btn {
    text-transform: uppercase;
  }
}
</style>
