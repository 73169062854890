import { ref } from 'vue'
import type { Ref } from 'vue'
import type { RES_CouponList, PROP_CouponList } from '../../../types/index'

const unusedCouponShow = ref(true)
const couponCodeRef = ref('')
  //券数据refs
const usableCouponList: Ref<RES_CouponList.CommonCoupon[]> = ref([])
const isFreeShippingRef = ref(false)
const selectCouponHasGift = ref(false)
const checkCouponsRef: Ref<RES_CouponList.CheckCouponsItem[] | null> = ref([])
const commonCouponListRef: Ref<RES_CouponList.CommonCoupon[] | null | undefined> = ref([])
const addedCouponListRef: Ref<RES_CouponList.CommonCoupon[] | null> = ref([])
const primeCouponListRef = ref([])
const bestCouponListRef: Ref<RES_CouponList.CommonCoupon[] | null> = ref([])
const addItemInfoListRef: Ref<RES_CouponList.CommonCoupon[] | null> = ref([])
// 不可用券refs
const disabledCouponListRef: Ref<RES_CouponList.CommonCoupon[] | null> = ref([])
//
const isLoaded = ref(false)
const couponListNotice = ref<string>('')
const alreadyExposeCouponInfo = ref<boolean>(false)
const isClickPrimeMoreRef = ref(false)
// 本地记录券使用数据
const couponInfoVal = ref<string | undefined>('')
const couponInfoLastCoupon = ref<string | undefined>('')
const couponInfoErr = ref<string>('')
// const couponInfoApplyCoupons = ref<object[]>([])
const couponInfoLastAddCoupon = ref<string>('')
const couponsApplyRef = ref<boolean>(false)
const bestCouponInfoRef = ref<PROP_CouponList.BestCoupon>({})
const stackableInfoRef = ref<PROP_CouponList.StackableInfo>({})
// 是否手动用券
const isManualUseCoupon = ref(false)
const cacheTargeCode = ref('')

export default {
  unusedCouponShow,
  couponCodeRef,
  usableCouponList,
  isFreeShippingRef,
  selectCouponHasGift,
  checkCouponsRef,
  commonCouponListRef,
  addedCouponListRef,
  primeCouponListRef,
  bestCouponListRef,
  addItemInfoListRef,
  disabledCouponListRef,
  isLoaded,
  couponListNotice,
  alreadyExposeCouponInfo,
  isClickPrimeMoreRef,
  couponInfoVal,
  couponInfoLastCoupon,
  couponInfoErr,
  couponInfoLastAddCoupon,
  couponsApplyRef,
  bestCouponInfoRef,
  stackableInfoRef,
  isManualUseCoupon,
  cacheTargeCode, //缓存支付列表卡bin优惠券code
  }
