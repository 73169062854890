<template>
  <div class="tax-content">
    <div
      v-for="item in taxList"
      :key="item.local_name"
      class="tax-content__tax"
    >
      <div class="tax-content__tax-name">
        <span>{{ item.local_name }}</span>
        <Icon
          v-if="!!item.tip || !!item.des"
          class="tax-content__tax-tips"
          name="sui_icon_doubt_12px_2"
          size="14px"
          color="#959595"
          @click="handleTipsShow(item)"
        />
      </div>
      <div class="tax-content__tax-value">
        {{ item.price_with_symbol }}
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { Icon } from '@shein-aidc/icon-vue3'
import { type C_CheckoutPrice, E_CheckoutPrice } from '../../../../types'

defineProps<{
  taxList: C_CheckoutPrice.TaxInteractionListItem[]
}>()

const emits = defineEmits(['show-tips'])

const handleTipsShow = (item) => {
  emits('show-tips', {
    event: E_CheckoutPrice.TipsClickedEvent.ShowTips,
    tips: item.tip || item.des,
  })
}
</script>
<style lang="less" scoped>
.tax-content {
  padding: 16/75rem 24/75rem;
  background-color: #F6F6F6;
  margin-bottom: 140/75rem;

  &__tax {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #767676;
    margin-top: 16/75rem;

    &:first-child {
      margin-top: 0;
    }
  }

  &__tax-name {
    margin-right: 24/75rem;
    display: flex;
    align-items: center;
    gap: 4/75rem;
  }

  &__tax-tips {
    align-items: end;
  }

  &__tax-value {
    color: #666;
  }
}
</style>
