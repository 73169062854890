import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
export { default, default as BCheckoutPrice } from './container.vue'
import type { DS_CheckoutPrice } from '../../types'
export type * from '../../types'

import { getFsDataRunner } from '../../common/dataSource'

export function getCheckoutPriceFsDataRunner(sourceParams?: Partial<DS_CheckoutPrice.SourceParams>) {
  return getFsDataRunner({ appConfigs: useAppConfigs(), ...sourceParams })
}
