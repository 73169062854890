import { ref } from 'vue'
import type { C_CouponList } from '../../../types'

const isShowLoading = ref(false)
// const { appEventCenter } = window as unknown as C_CouponList.CWindow
const confirmUseCoupon = ref(false) // 展示券确认弹窗
const cacheSaPageInfo = ref<C_CouponList.CacheSaPageInfo>(null)

const hasVariable = (v) => {
  return typeof v !== 'undefined' && typeof v === 'object' && v !== null
}

/**
 * @description 倒计时结束，刷新券列表状态
 * @param {boolean, triggerNotice, usableCouponList}
 * @returns {null}
 */
const handlePageOnload = (val, triggerNotice, usableCouponList) => {
  if (val) {
    // @ts-ignore
    cacheSaPageInfo.value = cacheSaPageInfo.value?.page_id ? cacheSaPageInfo.value : (hasVariable(SaPageInfo) ? SaPageInfo : {})
    const SaPageInfoCp = {
      page_id: 1667,
      page_name: 'page_checkout_coupon',
      page_param: {
        num_coupon: usableCouponList.value?.length,
        product_type: 'commontype',
      },
      start_time: new Date().getTime(),
    }
    // @ts-ignore
    SaPageInfo = SaPageInfoCp
    // @ts-ignore
    hasVariable(appEventCenter) && appEventCenter?.emit('pageOnload')

    setTimeout(() => {
      triggerNotice && triggerNotice({
        id: 'expose_added_coupon.comp_coupon-list',
      })
      triggerNotice && triggerNotice({
        id: 'expose_scenesabt.comp_coupon-list',
        data: {
          scenes: 'shipping_coupon_stackable',
          type: usableCouponList.value?.some(coupon => (
            ![53, 55].includes(+(coupon?.typeId || '')) && coupon?.applyFor == 9
          )) ? 1 : 0,
        },
      })
    }, 0)

  } else {
    // @ts-ignore
    if(hasVariable(cacheSaPageInfo.value)) {
      // @ts-ignore
      SaPageInfo = cacheSaPageInfo.value
      //@ts-ignore
      hasVariable(appEventCenter) && appEventCenter?.emit('pageOnload')
    }
  }
}

/**
 * @description 改变券列表loading
 * @param {boolean}
 * @returns {null}
 */
const changeLoading = (paylod) => {
  isShowLoading.value = paylod
}

/**
 * @description 改变券列表loading
 * @param {boolean}
 * @returns {null}
 */
const changeConfirmCoupon = (isShow) => {
  confirmUseCoupon.value = isShow ? true : false
}

export default {
  confirmUseCoupon,
  isShowLoading,
  handlePageOnload,
  changeLoading,
  changeConfirmCoupon,
}
