<template>
  <span class="qw-num-box">
    <span
      class="tras-box"
      :style="{ top: -18 * value +'px' }"
    >
      <span class="qwnum">0</span>
      <span
        v-for="i in 9"
        :key="i"
      >{{ i }}</span><br />
    </span>
  </span>
</template>
<script name="NumRun" setup lang="ts">
  import { defineProps } from 'vue'
  defineProps<{
    value: number,
  }>()
</script>
<style lang="less" scoped>
.qw-num-box {
   position: relative;
   display: inline-block;
   overflow: hidden;
   width: 10px;
   height: 18px;
   line-height: 18px;
   top: 4px;
 }
 .tras-box {
  position: absolute;
  left: 0;
  top: 0;
  height: auto;
  width: 100%;
  transform-origin: 0 0;
  transition: top 0.8s;
  span{
    display: block;
  }
}
</style>
