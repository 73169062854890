<template>
  <div>
    <addressListDrawer
      v-model:visible="isShowAddressListDrawer"
      :direction="direction"
      :type="showType"
      :modal="modal"
      :immediately-render="true"
      :close-font-size="48"
      append-to-body
      class="addresslist-drawer"
      @close-from-icon="hide"
    >
      <template #closeIcon>
        <Icon
          :name="GB_cssRight ? 'sui_icon_more_right_24px' : 'sui_icon_more_left_20px'"
          size="24px"
        />
      </template>
      <!-- 标题 -->
      <template #title>
        {{ language.Title }}
      </template>

      <!-- 内容 -->
      <div class="addresslist-drawer-content">
        <div class="addresslist-drawer-content__list">
          <AvailableAddress
            :addressList="availableAddressList"
            :language="language"
            @selectedAddress="handleAddressSelected"
            @editAddress="handleAddressEdit"
          />
          <UnavailableAddress
            :addressList="unavailableAddressList"
            :unavailableTips="unavailableTips"
            :language="language"
            @deleteAddress="handleDeleteAddress"
          />
        </div>
        <!-- 底部添加按钮 -->
        <div class="addresslist-drawer-content__footer">
          <Button
            :type="['primary','H80PX']"
            width="100%"
            @click="handleAddAddress"
          >
            + {{ language.AddText }}
          </Button>
        </div>
      </div>
    </addressListDrawer>
    <!-- 地址数量限制 -->
    <SDialog
      v-model:visible="isShowAddressUpperLimitTips"
      :show-close="false"
      append-to-body
    >
      <div>
        <Icon
          name="sui_icon_caution_12px_2"
          size="45px"
        />
        <div>
          {{ language.UpperLimit }}
        </div>
      </div>
    </SDialog>
    <!-- loading -->
    <Loading
      :show="isShowLoading"
      type="curpage"
    />
  </div>
</template>

<script name="BAddresslist" setup lang="ts">
import { ref, reactive, watch, onMounted, withDefaults, defineExpose, nextTick } from 'vue'
import { SDrawer as addressListDrawer } from '@shein-aidc/sui-drawer/mobile'
import { SButton as Button } from '@shein-aidc/sui-button/mobile'
import { SLoading as Loading } from '@shein-aidc/sui-loading/mobile'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { Icon } from '@shein-aidc/icon-vue3'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import AvailableAddress from './AvailableAddress.vue'
import UnavailableAddress from './UnavailableAddress.vue'
import { getFsDataRunner } from '../../common/dataSource'
import type { C_Addresslist, AS_Addresslist, DS_Addresslist } from '../../types'

const appConfigs = useAppConfigs()
const { GB_cssRight } = gbCommonInfo

// ---------- props ---------
const props = withDefaults(defineProps<{
  fsData?: DS_Addresslist.FS_DATA
  dataSource?: DS_Addresslist.DataSource
  analysisSource?: AS_Addresslist.AnalysisSource
  visible: boolean, // 控制显隐
  showType?: string, // 抽屉显示类型 normal/multi/full
  direction?: string, // 抽屉的方向 rtl/ltr/ttb/btt
  modal?: boolean, // 是否有遮罩
  isDev?: boolean, // 是否需要打印日志
  addressNumberUpperLimit?: boolean, // 是否需要地址数量的限制，主要用于添加地址
  addressListParams?: C_Addresslist.AddressListRequest // 地址列表的请求参数
  defaultAddressData: C_Addresslist.IDefaultAddress // 默认的地址
}>(), {
  visible: false,
  direction: 'rtl',
  showType: 'full',
  modal: false,
  isDev: false,
  addressNumberUpperLimit: true,
  addressListParams: () => ({ from: '', scene: 'checkout' }),
})

// ---------- emits ---------
const emits = defineEmits<{
  (event: 'update:visible', params: boolean): void
  (event: 'onSelected', params: { address: C_Addresslist.IDefaultAddress, index: number }): void
  (event: 'onAdd'): void
  (event: 'onEdit', params: C_Addresslist.IDefaultAddress): void
  (event: 'onDelete', params: C_Addresslist.IDefaultAddress): void
  (event: 'onError', params: Record<string, any>): void
  (event: 'onPageOnload'): void
}>()

// ---------- state ---------
const isLoaded = ref(false) // 组件加载完成
const isShowAddressListDrawer = ref(false) // 是否展示抽屉
const isShowLoading = ref(false)
const isShowAddressUpperLimitTips = ref(false) // 是否展示地址数量的上限提醒

const addressCount = ref<number>(0) // 地址总数
const availableAddressList = ref<C_Addresslist.IDefaultAddress[]>([]) // 地址列表信息
const unavailableTips = ref<string>('') // 不能使用的地址提示
const unavailableAddressList = ref<C_Addresslist.IDefaultAddress[]>([]) // 不可用的地址列表信息

// 语言信息
const language: C_Addresslist.LanguageType = reactive({
  Title: 'My Address', AddText: '', ConfirmTitle: '', ConfirmDelete: '', ConfirmCancel: '', Collection: '', Default: '', Home: '',
  Work: '', Edit: '', NotShippingDesc: '', UpperLimit: '',
})

// ---------- methods ---------
/**
 * @description: 获取地址列表、多语言的信息
 * @return {*}
 */
const getAddressListData = async (operateType?: string):Promise<void> => {
  isShowLoading.value = true

  props.isDev && console.log('🌟🍓🍓请求地址列表数据')

  const { fsRunner } = getFsDataRunner({ appConfigs, dataSource: props.dataSource })
  const { getAddressList, langs = {} } = await fsRunner({ getAddressList: [props.addressListParams] })

  isShowLoading.value = false
  Object.assign(language, langs)

  if (getAddressList.code === '0' && getAddressList.info) {
    const { address = [], gray_address_msg, count } = getAddressList.info || {}
    const canUseAddress: Array<C_Addresslist.IDefaultAddress> = []
    const unCanUseAddress: Array<C_Addresslist.IDefaultAddress> = []

    address.forEach(item => { Number(item.isGray) === 1 ? unCanUseAddress.push(item) : canUseAddress.push(item) })

    availableAddressList.value = handleAddressFirstSelected(canUseAddress, operateType)
    unavailableAddressList.value = unCanUseAddress
    unavailableTips.value = gray_address_msg || language.NotShippingDesc
    addressCount.value = count
  }

  if (getAddressList.code !== '0' || getAddressList.info === null || getAddressList.info === undefined || !Object.keys(langs).length) {
    props.isDev && console.log('🌟🍓🍓报错', { code: getAddressList.code, info: getAddressList.info, langs })
    nextTick(() => {
      emits('update:visible', false)
      handlePageOnScroll()
      emits('onError', { code: getAddressList.code, info: getAddressList.info, langs })
    })
  }
  else {
    isShowAddressListDrawer.value = true
  }
}

/**
 * @description: 处理第一个选中的地址，如果外部没有传送默认的地址数据，取第一个值作为兜底，防止没有选中
 * @param {*} address 接口返回的地址列表
 * @param {*} operateType 操作类型
 * @return {*} 给地址列表增加一个selected属性，返回新的地址列表
 */
const handleAddressFirstSelected = (address: C_Addresslist.IDefaultAddress[] = [], operateType?: string): C_Addresslist.IDefaultAddress[] => {
  let defaultSelectedAddress: any = {}

  if (!props.defaultAddressData && !!address.length) {
    const filterAddress = address.filter(i => Number(i.isDefault) === 1 && Number(i.isGray) !== 1)
    defaultSelectedAddress = filterAddress.length ? filterAddress[0] || {} : address[0] || {}
  }
  else {
    defaultSelectedAddress = props.defaultAddressData || {}
  }

  if (operateType === 'add') {
    const targetElement = address.find(_ => _.addressId === defaultSelectedAddress?.addressId)
    if (targetElement) {
      const filteredArray = address.filter(element => element.addressId !== defaultSelectedAddress?.addressId)
      filteredArray.unshift({...targetElement, selected: 1 })
      return filteredArray
    }
  }
  return address.map(item => Object.assign({}, item, { selected: item.addressId === defaultSelectedAddress?.addressId ? 1 : 0 })) || []
}

/**
 * @description: 关闭地址列表抽屉
 * @return {*}
 */
const hide = (): void => {
  nextTick(() => {
    isShowAddressListDrawer.value = false
    emits('update:visible', false)
    handlePageOnScroll()
  })
}

/**
 * @description: 处理选中某个地址
 * @param {*} address 选中的地址
 * @param {*} index 选中的索引
 * @return {*}
 */
const handleAddressSelected = ({ address, index }): void => {
  props.isDev && console.log('🌟🍓🍓选择地址', { address, index })
  availableAddressList.value = availableAddressList.value.map(item => Object.assign({}, item, { selected: item.addressId === address.addressId ? 1 : 0 }))
  emits('onSelected', { address, index })
  hide()
}

/**
 * @description: 处理编辑地址
 * @param {*} options 需要编辑的地址信息
 * @return {*}
 */
const handleAddressEdit = (options: C_Addresslist.IDefaultAddress): void => {
  props.isDev && console.log('🌟🍓🍓编辑地址', options)
  emits('onEdit', options)
}

/**
 * @description: 添加地址
 * @return {*}
 */
const handleAddAddress = (): void => {
  props.isDev && console.log('🌟🍓🍓添加地址', addressCount.value)

  if (props.addressNumberUpperLimit && Number(addressCount.value) >= 20) {
    isShowAddressUpperLimitTips.value = true
    setTimeout(() => {
      isShowAddressUpperLimitTips.value = false
    }, 3000)
    return
  }
  emits('onAdd')
}

/**
 * @description: 处理删除地址
 * @param {*} options 需要删除的地址信息
 * @return {*}
 */
const handleDeleteAddress = (options: C_Addresslist.IDefaultAddress): void => {
  props.isDev && console.log('🌟🍓🍓删除地址', options)
  emits('onDelete', options)
}

/**
 * @description: 处理页面不能滚动的问题
 * @return {*}
 */
 const handlePageOnScroll = () => {
  const isExistDom = document?.body?.classList?.contains('S-popup-parent__hidden')

  if (isExistDom) {
    document.body.classList.remove('S-popup-parent__hidden')
  }
}

watch(() => props.visible, (val) => {
  if (val && isLoaded.value) {
    props.isDev && console.log('🌟🍓🍓打开地址')
    getAddressListData()
  }
  if (!val && isShowAddressListDrawer.value) {
    props.isDev && console.log('🌟🍓🍓关闭地址')
    hide()
  }
})

onMounted(() => {
  props.isDev && console.log('🌟🍓地址列表mounted')
  isLoaded.value = true
})

defineExpose({ getAddressListData })
</script>

<style lang="less" scoped>
.addresslist-drawer-content {
  position: relative;
  background-color: #f6f6f6;
  min-height: 100%;
  &__list {
    height: 100%;
    padding-top: 20/75rem;
    padding-bottom: 112/75rem;
  }
  &__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 16/75rem 24/75rem;
    background: #fff;
  }
}
</style>
